// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privates-js": () => import("./../../../src/pages/privates.js" /* webpackChunkName: "component---src-pages-privates-js" */),
  "component---src-pages-shows-js": () => import("./../../../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */),
  "component---src-templates-stripper-template-js": () => import("./../../../src/templates/stripper-template.js" /* webpackChunkName: "component---src-templates-stripper-template-js" */)
}

